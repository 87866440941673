import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Col, Row } from '@bootstrap-styled/v4'
import { H2, H6 } from '../Typography'

const RowTextBlock = styled(Row)`
  padding-bottom: 3.5rem;
`

const SectionHeading = ({header, subTitle }) => (
  <>
    <Row className="justify-content-center">
      <Col xs="12" md="6" className="d-flex justify-content-center">
        <H2 className="text-center">{header}</H2>
      </Col>
    </Row>
    <RowTextBlock className="justify-content-center mt-3">
      <Col xs="12" md="6" className="d-flex justify-content-center">
        <H6 className="text-center">{subTitle}</H6>
      </Col>
    </RowTextBlock>
  </>
)

SectionHeading.propTypes = {
  header: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}

export default SectionHeading
