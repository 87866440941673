import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import breakpoints from '../../util/style/breakpoints'

const Hero = styled.div`
  position: relative;
  margin-right: -15px;
  margin-left: -15px;
  ${breakpoints.md`
    margin-left: inherit;
    margin-right: inherit;
  `};
`

const ImgWrapper = styled.div`
  height: 205px;
  position: relative;

  ${breakpoints.md`
    height: 600px;
  `};
`

const HeroImageHeader = ({ imgHeader }) => (
  <>
    {imgHeader && (
      <Hero>
        <ImgWrapper>
          <GatsbyImage
            alt=""
            image={imgHeader}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </ImgWrapper>
      </Hero>
    )}
  </>
)

export default injectIntl(HeroImageHeader)
