import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Small } from '@bootstrap-styled/v4'
import { GatsbyImage } from 'gatsby-plugin-image'
import { H4 } from '../Typography'
import { Link } from '../Link'
import ReadMore from '../ReadMore'
import breakpoints from '../../util/style/breakpoints'

const BgImageWrapper = styled.div`
  display: grid;
  background-color: ${(props) => props.theme.$gray};
  min-height: 217px;
  width: auto;

  .bg-image {
    opacity: 30%;
  }

  .content {
    padding: 1.5rem;
  }

  ${breakpoints.md`
    .content {
      padding: 2.5rem;
    }

    .bg-image {
      transition: all 0.3s ease-in-out;
      opacity: 0;
    }
    &:hover {
      .bg-image {
        opacity: 30%;
      }
    }
  `};
`

const CardWithCta = ({ title, subTitle, linkTo, bgImage }) => (
  <Link to={linkTo}>
    <BgImageWrapper>
      <GatsbyImage
        alt={title}
        image={bgImage}
        style={{
          gridArea: '1/1',
        }}
        className="bg-image"
        layout="fullWidth"
      />
      <div
        style={{
          gridArea: '1/1',
          position: 'relative',
          placeItems: 'center',
          display: 'grid',
        }}
      >
        <div className="content d-flex flex-column align-items-md-center justify-content-center">
          <Small
            color="primary"
            className="text-uppercase font-weight-medium text-md-center mb-3"
          >
            {subTitle}
          </Small>
          <H4 className="text-md-center mb-3">{title}</H4>
          <ReadMore />
        </div>
      </div>
    </BgImageWrapper>
  </Link>
)

CardWithCta.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
}

export default CardWithCta
