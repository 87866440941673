import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import Icon from '../Icons'

const ReadMore = ({ intl }) => (
  <div className="font-weight-medium d-flex align-items-center">
    <span className="pr-2">{`${intl.formatMessage({ id: 'READ_MORE' })}`}</span>
    <Icon fill="#b98752" width="20" name="arrow" />
  </div>
)

export default injectIntl(ReadMore)
