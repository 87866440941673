import styled from 'styled-components';

const Card = styled.div`
  background-color: ${props => props.theme.$gray};
  min-height: 56px;
  width: auto;
  padding: 1.5rem;
`;

export default Card;
