import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { Row, Col } from '@bootstrap-styled/v4'
import SectionHeading from '../SectionHeading'
import { P } from '../Typography'
import Card from '../UI/Card'
import GoogleRating from './GoogleRating'
import Button from '../Buttons'
import Icon from '../Icons'
import OutboundLink from '../Link/OutboundLink'

const Review = styled(Card)`
  margin-bottom: 1.5rem;
  .review-text {
    position: relative;
    height: 12.25rem;
    overflow: hidden;
  }
  .review-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 3rem 0;
    background-image: linear-gradient(
      to bottom,
      rgba(31, 31, 31, 0),
      rgba(31, 31, 31, 1)
    );
  }
`

const Name = styled(P)`
  margin-bottom: 0.5rem;
`

const GoogleReviews = ({
  intl,
  title,
  subTitle,
  rating,
  userRatingsTotal,
  googleReviews,
  placeId,
}) => {

  const googleReviewsSubTitle = `${userRatingsTotal} ${subTitle} ${rating}`
  return (
    <>
      <SectionHeading header={title} subTitle={googleReviewsSubTitle} />
      <Row>
        {googleReviews &&
          googleReviews.length &&
          googleReviews.map((review) => (
            <Col xs={12} md={4} key={review.node.id}>
              <Review>
                <div className="header mb-4">
                  {review.node.authorName && (
                    <Name className="font-weight-medium">
                      {review.node.authorName}
                    </Name>
                  )}
                  {review.node.rating && (
                    <GoogleRating rating={review.node.rating} />
                  )}
                </div>
                {review.node.text && (
                  <div className="review-text mb-3">
                    <P>{review.node.text}</P>
                    <div className="review-fade" />
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <OutboundLink
                    to={`https://search.google.com/local/reviews?placeid=${placeId}`}
                    target="_blank"
                    from="google-reviews"
                    className="d-flex"
                  >
                    <P color="#b98752" className="font-weight-medium">
                      {intl.formatMessage({ id: 'READ_MORE' })}
                    </P>
                    <Icon name="externalLink" width="24" className="ml-2" />
                  </OutboundLink>
                </div>
              </Review>
            </Col>
          ))}
      </Row>
      <Row className="f-flex justify-content-center">
        <Col md={4} className="f-flex justify-content-center">
          {placeId && (
            <Button
              name="primary"
              type="link"
              target="_blank"
              className="mt-5 w-100"
              to={`https://search.google.com/local/reviews?placeid=${placeId}`}
              from="google reviews"
            >
              {intl.formatMessage({ id: 'READ_GOOGLE_REVIEWS' })}
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default injectIntl(GoogleReviews)
