import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
import { Container, Col, Row } from '@bootstrap-styled/v4'
import { getImage } from 'gatsby-plugin-image'

import Section from '../../UI/Section'
import Button from '../../Buttons'
import CardWithCta from '../../CardWithCta'

const ServicesBlock = ({ intl, landlords, realEstateAgents, renters }) => (
  <StaticQuery
    query={graphql`
      query {
        imageTenants: file(relativePath: { eq: "diensten/huurders.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 560
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        imageRealEstateAgents: file(
          relativePath: { eq: "diensten/makelaars.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 560
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        imageLandLords: file(relativePath: { eq: "diensten/verhuurders.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 560
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        verhuurdersSummaryJson {
          en {
            title
          }
          nl {
            title
          }
        }
        huurdersSummaryJson {
          en {
            title
          }
          nl {
            title
          }
        }
        makelaarsSummaryJson {
          en {
            title
          }
          nl {
            title
          }
        }
      }
    `}
    render={(data) => {
      const imageTenants = getImage(data.imageTenants)
      const imageRealEstateAgents = getImage(data.imageRealEstateAgents)
      const imageLandLords = getImage(data.imageLandLords)
      const { title: titleVerhuurders } = data.verhuurdersSummaryJson[
        intl.locale
      ]
      const { title: titleHuurders } = data.huurdersSummaryJson[intl.locale]
      const { title: titleMakelaars } = data.makelaarsSummaryJson[intl.locale]

      // Adjust col length based on how many cards to show
      const colLength = []
      let colSize = null;

      if (landlords) {
        colLength.push(landlords);
      }
      if (renters) {
        colLength.push(renters);
      }
      if (realEstateAgents) {
        colLength.push(realEstateAgents);
      }

      if(colLength.length <= 1) {
        colSize = 12;
      }
      if(colLength.length === 2) {
        colSize = 6;
      }
      if(colLength.length >= 3) {
        colSize = 4;
      }

      return (
        <Section>
          <Container>
            <Row className="mb-5">
              {landlords && (
                <Col sm={12} md={colSize} className="mb-4">
                  <CardWithCta
                    title={titleVerhuurders}
                    subTitle={`${intl.formatMessage({ id: 'LANDLORDS' })}`}
                    linkTo="/diensten-verhuurders"
                    bgImage={imageLandLords}
                  />
                </Col>
              )}
              {renters && (
                <Col sm={12} md={colSize} className="mb-4">
                  <CardWithCta
                    title={titleHuurders}
                    subTitle={`${intl.formatMessage({ id: 'TENANTS' })}`}
                    linkTo="/diensten-huurders"
                    bgImage={imageTenants}
                  />
                </Col>
              )}
              {realEstateAgents && (
                <Col sm={12} md={colSize} className="mb-4">
                  <CardWithCta
                    title={titleMakelaars}
                    subTitle={`${intl.formatMessage({
                      id: 'REAL_ESTATE_AGENTS',
                    })}`}
                    linkTo="/diensten-makelaars"
                    bgImage={imageRealEstateAgents}
                  />
                </Col>
              )}
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} md={4} lg={3}>
                <Button
                  name="primary"
                  type="internal-link"
                  className="mt-3 mt-md-0"
                  to="/diensten"
                  from="home"
                >
                  {intl.formatMessage({ id: 'SEE_OUR_SERVICES' })}
                </Button>
              </Col>
            </Row>
          </Container>
        </Section>
      )
    }}
  />
)

export default injectIntl(ServicesBlock)
