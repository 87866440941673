import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Small, Container, Col, Row } from '@bootstrap-styled/v4'
import Section from '../UI/Section'
import { H1, H6 } from '../Typography'
import breakpoints from '../../util/style/breakpoints'

const SmallTitle = styled(Small)`
  color: ${(props) => props.theme['$brand-primary300']};
`

const SectionPageHeader = styled(Section)`
  margin-bottom: ${props => props.xlMargin ? '2.5rem' : '1.5rem'};
  ${breakpoints.md`
    margin-bottom: ${props => props.xlMargin ? '12.5rem' : '6.5rem'};
  `};
  ${breakpoints.xl`
    margin-bottom: ${props => props.xlMargin ? '14.5rem' : '6.5rem'};
  `};
`

class PageHeader extends React.PureComponent {
  render() {
    const { title, subTitle, description, xlMargin } = this.props
    return (
      <SectionPageHeader xlMargin={xlMargin}>
        <Container>
          {subTitle && (
            <Row className="mb-3 d-flex justify-content-md-center">
              <Col
                xs={12}
                md={10}
                className="d-flex align-items-center justify-content-md-center"
              >
                <SmallTitle className="text-uppercase font-weight-bold text-md-center">
                  {subTitle}
                </SmallTitle>
              </Col>
            </Row>
          )}
          {title && (
            <Row className="d-flex justify-content-md-center">
              <Col
                xs={12}
                md={10}
                className="d-flex align-items-center justify-content-md-center"
              >
                <H1 className="text-md-center">{title}</H1>
              </Col>
            </Row>
          )}
          {description && (
            <Row className="d-flex justify-content-md-center mt-3">
              <Col
                xs={12}
                md={10}
                className="d-flex align-items-center justify-content-md-center"
              >
                <H6 className="text-md-center">{description}</H6>
              </Col>
            </Row>
          )}
        </Container>
      </SectionPageHeader>
    )
  }
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
}

PageHeader.defaultProps = {
  subTitle: '',
}

export default PageHeader
