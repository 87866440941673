import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
import GoogleReviews from '../../GoogleReviews'

const GoogleReviewsBlock = ({ intl }) => (
  <StaticQuery
    query={graphql`
      query {
        googleReviewsSummaryJson {
          en {
            title
            description
          }
          nl {
            title
            description
          }
        }
        allGooglePlacesReview(limit: 3) {
          edges {
            node {
              id
              authorName: author_name
              rating
              text
              language
            }
          }
        }
        allGooglePlacesPlace {
          nodes {
            rating
            userRatingsTotal: user_ratings_total
            placeId: place_id
          }
        }
      }
    `}
    render={(data) => {
      const { title, description } = data.googleReviewsSummaryJson[intl.locale]
      const googleReviews = data.allGooglePlacesReview.edges
      const {
        rating,
        userRatingsTotal,
        placeId,
      } = data.allGooglePlacesPlace.nodes[0]

      return (
        <GoogleReviews
          title={title}
          subTitle={description}
          rating={rating}
          userRatingsTotal={userRatingsTotal}
          googleReviews={googleReviews}
          placeId={placeId}
        />
      )
    }}
  />
)

export default injectIntl(GoogleReviewsBlock)
