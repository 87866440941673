import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icons'

const GoogleRating = ({ rating }) => {
  const stars = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.floor(rating); i++) {
    stars.push(<Icon name="star-full" key={i} />)
  }

  return (
    <>
      {stars}
      {rating && !Number.isInteger(rating) && <Icon name="star-half" />}
    </>
  )
}

GoogleRating.propTypes = {
  rating: PropTypes.number.isRequired,
}

export default GoogleRating
